import React, { useState } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaYoutube
} from "react-icons/fa";
// import { StickyBox } from 'react-sticky-box';
import "./social.css";

const SocialMedia = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = () => {
    // Replace the number with the desired WhatsApp number
    const phoneNumber = "+918882745457";
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp chat link
    window.open(whatsappLink, "_blank");
  };

  return (
    <div>
      {/* <div className="social-media-buttons">
        <a
          href="https://www.facebook.com/example"
          target="_blank"
          rel="noopener noreferrer"
          className="facebook"
        >
          <FaFacebookF className="facebook1" />
        </a>
        <a
          href="https://www.twitter.com/example"
          target="_blank"
          rel="noopener noreferrer"
          className="twitter"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.instagram.com/example"
          target="_blank"
          rel="noopener noreferrer"
          className="instagram"
        >
          <FaInstagram />
        </a>
      </div> */}

      <div id="mySidenav" className="sidenav">
        <a href="https://www.facebook.com/people/Eye-caretaker/100095520750889/?mibextid=LQQJ4d" target="_blank" id="facebook">
          Facebook
          <FaFacebookF className="facebook" />
        </a>

        {/* <a href="https://www.youtube.com/@EyeCaretaker" target="_blank" id="twitter">
          Twitter <FaTwitter className="twitter"/>
        </a> */}

        <a href="https://www.instagram.com/eyecaretaker/" target="_blank" id="instagram">
          Instagram <FaInstagram className="instagram"/>
        </a>
       
        <a href="https://www.youtube.com/@EyeCaretaker" target="_blank"  id="youtube">
          YouTube <FaYoutube className="youtube"/>
        </a>
      </div>

      <div className="whatsapp-button">
        <a
          href="https://www.instagram.com/example"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp"
          onMouseEnter={handleDropdownToggle}
          onMouseLeave={handleDropdownToggle}
          onClick={() => handleClick()}
        >
          <h6>CHAT WITH US </h6>
          <FaWhatsapp className="whatsapp-icon" />
        </a>
      </div>
      <div className="whatsapp-button-mobile">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="whatsappMobile"
          onMouseEnter={handleDropdownToggle}
          onMouseLeave={handleDropdownToggle}
          onClick={() => handleClick()}
        >
          <FaWhatsapp className="whatsapp-icon" />
        </a>
        </div>
    </div>
  );
};

export default SocialMedia;
