import React from "react";
import './home.css'
import Header from "./header/header";
import Blogs from "./blogs/blogs";
import SocialMedia from "./socialMedia/social";
import Hero from "./hero/hero";
import Info from "./info/info";
import Portfolio from "./portfolio/portfolio";
import Footer from "./footer/footer";

const Home = () => {

  

  return (
    <div>
    {/* <UpperContact/> */}
      {/* <Header /> */}
      <Hero/>
        <Info/>
        <Portfolio/>
        <Footer/>
        <SocialMedia/>
      {/* <Blogs/> */}
    </div>
  );
};

export default Home;


// import React, { useState } from "react";
// import "./portfolio.css";
// import yt1 from "../../images/yt1.JPG";
// import yt2 from "../../images/yt2.JPG";
// import yt3 from "../../images/yt3.JPG";
// import yt4 from "../../images/yt4.JPG";
// import yt5 from "../../images/yt5.JPG";
// import yt6 from "../../images/yt6.JPG";
// import yt7 from "../../images/yt7.JPG";
// import yt8 from "../../images/yt8.JPG";

// const Portfolio = () => {
//   const youTube = [
//     {
//       id: 1,
//       name: "EyeZone W01",
//       imageUrl: yt1,
//       price: 2500,
//       otherPrice: 3500,
//     },
//     {
//       id: 2,
//       name: "EyeZone W02",
//       imageUrl: yt2,
//       price: 1500,
//       otherPrice: 2500,
//     },
//     {
//       id: 3,
//       name: "EyeZone W03",
//       imageUrl: yt3,
//       price: 1800,
//       otherPrice: 2800,
//     },
//     {
//       id: 4,
//       name: "EyeZone W04",
//       imageUrl: yt4,
//       price: 2000,
//       otherPrice: 3000,
//     },
//     {
//       id: 5,
//       name: "EyeZone W05",
//       imageUrl: yt5,
//       price: 1000,
//       otherPrice: 2000,
//     },
//     {
//       id: 6,
//       name: "EyeZone W06",
//       imageUrl: yt6,
//       price: 2200,
//       otherPrice: 3200,
//     },
//     {
//       id: 7,
//       name: "EyeZone W07",
//       imageUrl: yt7,
//       price: 1800,
//       otherPrice: 2800,
//     },
//     {
//       id: 8,
//       name: "EyeZone W08",
//       imageUrl: yt8,
//       price: 1600,
//       otherPrice: 2600,
//     },
//   ];

//   const [showDetails, setShowDetails] = useState(false);

//   const handleMouseEnter = () => {
//     setShowDetails(true);
//   };

//   const handleMouseLeave = () => {
//     setShowDetails(false);
//   };

//   return (
//     <div className="portfolio">
//       <div className="portfolioSection1">
//         <h5> - YouTube</h5>
//         <h2 className="portfolioSection1-intro"> Our most popular videos</h2>
//         <h5>
//           We have 40K strong YouTube family, and having millions of views on
//           youtube videos.
//         </h5>
//       </div>

//       <div className="YoutubeVideosSection">
//         {youTube.map((item) => (
//           <div
//             key={item.id}
//             className="YoutubeVideos"
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave}
//           >
//           <img
//             src={item.imageUrl}
//             alt="Image 1"
//             loading="lazy"
//             className="yt"
//             width="280"
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave}
//           />
//           </div>
//         ))}
//         {showDetails && (
//               <div className="item-details">
//                <h4>Visit Site</h4>
//               </div>
//             )}
//       </div>
//       {/* <div className="YoutubeVideosSection">
//         {youTube.map((item) => (
//           <div
//             key={item.id}
//             className="YoutubeVideos"
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave}
//           >
//             <img
//               src={item.imageUrl}
//               alt="Image 1"
//               loading="lazy"
//               className="yt"
//               width="250"
//             />
//             {showDetails && (
//               <div className="item-details">
//                 <h2>{item.name}</h2>
//                 <span>₹ {item.price}</span>
//                 <p>₹ {item.otherPrice}</p>
//               </div>
//             )}
//           </div>
//         ))}
//       </div> */}
//     </div>
//   );
// };

// export default Portfolio;


/* .portfolio{
    background-color: #232534;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.portfolioSection1{
    text-align: justify;
    margin: 50px;
    width: 25%;
    margin-left: 20vh;
    margin-top: 20px;
}
.portfolioSection1-intro{
    margin-left: 10px;
    color: #eaeaef;
}

.YoutubeVideos{
    margin-top: -20px;
    margin-left: 80px;
    display: flex;
}

.yt{
    display: flex;
    margin: 20px;
    flex-direction: row;
    flex-wrap: wrap;
} */







// .portfolio {
//   background-color: #232534;
//   display: flex;
//   flex-direction: column;
// }

// .portfolioSection1 {
//   text-align: justify;
//   margin: 50px;
//   width: 25%;
//   margin-left: 20vh;
//   margin-top: 20px;
// }
// .portfolioSection1-intro {
//   margin-left: 10px;
//   color: #eaeaef;
// }

// .YoutubeVideosSection {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   margin-top: -20px;
//   margin-bottom: 80px;
// }
// .YoutubeVideos {
//   display: flex;
//   overflow: hidden;
//   transition: transform 0.3s ease-in-out;
//   align-items: center;
//   justify-content: center;
//   margin: 15px;
//   position: relative;
// }

// .YoutubeVideos:hover {
//   transform: scale(1.05);
// }

// .yt {
  
//   max-width: 100%;
//   height: auto;
// }

// /* .yt:hover {
//   transform: scale(1.05);
// } */

// .item-details {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.7);
//   color: white;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   opacity: 0;
//   transition: opacity 0.3s ease-in-out;
//   z-index: 999;
// }

// .YoutubeVideos:hover .item-details {
//   opacity: 1;
// }

// .item-details button {
//   margin-left: 38vh;
//   margin-top: 20px;
//   padding: 10px;
//   width: 15vh;
//   color: #fead20;
//   background-color: transparent;
//   border: 1px solid #fead20;
//   font-size: 16px;
// }
// .item-details button:hover {
//   margin-left: 38vh;
//   margin-top: 20px;
//   padding: 10px;
//   width: 15vh;
//   color: #eaeaef;
//   background-color: #fead20;
//   border: 1px solid #fead20;
//   cursor: pointer;
// }

// @media (max-width: 768px) {
//   .portfolioSection1 {
//     /* margin: 0 auto;  */
//     width: 90%;
//     margin-left: 0;
//     margin-top: 0;
//     align-self: center;
//   }

//   .YoutubeVideos:hover {
//     transform: scale(1.05);
//   }

//   .yt {
//     position: relative;
//     max-width: 80%;
//     height: auto;
//   }
// }

// @media (min-width: 769px) and (max-width: 1024px) {
//   .portfolioSection1 {
//   }
// }

// @media (min-width: 1025px) {
//   .portfolioSection1 {
//   }
// }

// /* Add any other styles as needed */
