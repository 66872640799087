import React from "react";
import "./info.css";
import { FaYoutube, FaCalendarAlt, FaNotesMedical } from "react-icons/fa";

const Info = () => {
  return (
    <div className="infoSection">
      <div className="info">
        <div className="info-side1">
          <h5>- Contact</h5>
          <h2>Any type of query & discussions</h2>
          <p className="infoLines">
            Take an appointment, and get solution of your personal queries or
            visit our YouTube channel.
          </p>

          <a>
            <h3 className="ytgetintouch">EYECARETAKER ➜</h3>
          </a>
        </div>
        <div className="info-side2">
          <h2>
            "To have beautiful lips, say beautiful things. To have beautiful
            eyes, look at people and see the good in them."
          </h2>
          <p className="infoLines">
            We at Eye Caretaker wish to offer everyone the best of our
            potential. We are pleased to communicate and connect to assist you
            with your questions. No matter where or when we will be there for
            you to be your perfect solution.
          </p>
          <div className="infoNumSection">
            <div className="infoNum">
              <h1 className="infoNumber">6</h1>
              <div className="infoLines2">
                <p>Years of</p>
                <p>experience</p>
              </div>
            </div>
            <div className="infoNum">
              <h1 className="infoNumber">5000</h1>
              <div className="infoLines2">
                <p>patients</p>
                <p>diagnosed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="infoBoxSection">
        <a href="https://www.youtube.com/@EyeCaretaker" target="_blank">
          <div className="infoBoxes">
            <h3>Visit Our YouTube Channel</h3>
            <FaYoutube className="infoBoxIcons" />
          </div>
        </a>
        <div className="infoBoxes">
          <h3>Book an Appointment</h3>
          <FaCalendarAlt className="infoBoxIcons" />
        </div>
        {/* ?subject='Hello from Abstract!'&body='Just popped in to say hello' */}
        <a href="mailto:knowledgeoptical@gmail.com">
          <div className="infoBoxes">
            <h3>Online Enquiry / Email Us</h3>
            <FaNotesMedical className="infoBoxIcons" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Info;
