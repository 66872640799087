import React, { useState } from "react";
import "./portfolio.css";
import yt1 from "../../images/yt1.JPG";
import yt2 from "../../images/yt2.JPG";
import yt3 from "../../images/yt3.JPG";
import yt4 from "../../images/yt4.JPG";
import yt5 from "../../images/yt5.JPG";
import yt6 from "../../images/yt6.JPG";
import yt7 from "../../images/yt7.JPG";
import yt8 from "../../images/yt8.JPG";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

const Portfolio = () => {
  const youTube = [
    {
      id: 1,
      imageUrl: yt1,
      videoURL: "https://youtu.be/gT8adIqQMSY?si=HoDZXhFGdvSk5V3A",
    },
    {
      id: 2,
      imageUrl: yt2,
      videoURL: "https://youtu.be/7Il8fiMo-UM?si=l_ls2DD05YTLKCjm",
    },
    {
      id: 3,
      imageUrl: yt3,
      videoURL: "https://youtu.be/gEEgBiiG5ls?si=2wIYnmqoD2WD25Kzs",
    },
    {
      id: 4,
      imageUrl: yt4,
      videoURL: "https://youtu.be/4Y3VjrBGhxE?si=CvzP1mHeDH_3gE_6",
    },
    {
      id: 5,
      imageUrl: yt5,
      videoURL: "https://youtu.be/cwVA9ko7zWk?si=0LXdEbJLVMm_VMaw",
    },
    {
      id: 6,
      imageUrl: yt6,
      videoURL: "https://youtu.be/p2eX_mUlyEk?si=QW5AWvebJchYlKPh",
    },
    {
      id: 7,
      imageUrl: yt7,
      videoURL: "https://youtu.be/cMIZ6N_wfCY?si=_zQraM1l9mfeoJOe",
    },
    {
      id: 8,
      imageUrl: yt8,
      videoURL: "https://youtu.be/9pkTapexYzs?si=eAkXdh6LCiazBAmw",
    },
  ];

  const [showDetails, setShowDetails] = useState(false);

  const handleMouseEnter = () => {
    setShowDetails(true);
  };

  const handleMouseLeave = () => {
    setShowDetails(false);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="portfolio">
      <div className="portfolioSection1">
        <h5> - YouTube</h5>
        <h2 className="portfolioSection1-intro"> Our most popular videos</h2>
        <h5>
          We have 40K strong YouTube family, and having millions of views on
          youtube videos.
        </h5>
      </div>

      <div className="YoutubeVideosSection">
        {youTube.map((item) => (
          <div
            key={item.id}
            className="YoutubeVideos"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={item.imageUrl}
              alt="Image 1"
              loading="lazy"
              className="yt"
              width="240"
            />
            {showDetails && (
              <div className="item-details">
                <button
                  role="link"
                  onClick={() => openInNewTab(item.videoURL)}
                  >Watch Now
                  <FaYoutube className="youtube" />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      {/* <div className="YoutubeVideosSection">
        {youTube.map((item) => (
          <div
            key={item.id}
            className="YoutubeVideos"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={item.imageUrl}
              alt="Image 1"
              loading="lazy"
              className="yt"
              width="250"
            />
            {showDetails && (
              <div className="item-details">
                <h2>{item.name}</h2>
                <span>₹ {item.price}</span>
                <p>₹ {item.otherPrice}</p>
              </div>
            )}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Portfolio;
