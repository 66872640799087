import React, { useEffect, useState, useRef } from "react";
import "./hero.css";
import img from "../../images/img.jpeg";
import img2 from "../../images/img2.PNG";
import img3 from "../../images/img3.png";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaBars,
  FaTimes,
} from "react-icons/fa";

const Hero = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="hero">
      <div className="header">
        <h2 className="brandName">EYECARETAKER</h2>
        <div className={`navTitle ${isMenuOpen ? "open" : ""}`}>
          <a>Home</a>
          <a>About Us</a>
          <a>Contact Us</a>
        </div>
        <div className="mobile-menu-icon" onClick={handleMenuToggle}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>

      <div className="heroTitle">
        <h1>Deepak</h1>
        <h1>Thakur</h1>
        <p className="nameLine">__</p>

        <div className="socialIcons">
          <a href="https://www.facebook.com/people/Eye-caretaker/100095520750889/?mibextid=LQQJ4d" target="_blank">
            <FaFacebookF className="facebook" />
          </a>
          {/* <a href="https://www.youtube.com/@EyeCaretaker" target="_blank">
            <FaTwitter className="twitter" />
          </a> */}
          <a href="https://www.instagram.com/eyecaretaker/" target="_blank">
            <FaInstagram className="instagram" />
          </a>
          <a href="https://www.youtube.com/@EyeCaretaker" target="_blank">
            <FaYoutube className="youtube" />
          </a>
        </div>
      </div>
      <img
        src={img2}
        alt="Deepak Thakur"
        width="600"
        height="400"
        className="userimage"
      ></img>
      <div className="nameSection2">
        <h3>Dr. Deepak Thakur</h3>
      </div>
      <div className="introSection">
        <h5>- Introduction</h5>
        <h2>Eye Caretaker is an organization founded by Dr. Deepak Thakur</h2>
        <p className="introSectionLines">
          A well-known and well-versed optometrist. This is a fantastic company
          that provides all of the information you need to know about your eyes.
          Eye Caretaker is a well-known location for the best eye consultation
          and any eye surgery.
        </p>

        <button>Know More ➜</button>
      </div>
    </div>
  );
};

export default Hero;
