import React from "react";
import "./footer.css";
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaWhatsapp,
    FaYoutube
  } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
    <div className="footerSection">
      <div className="footerSection1">
        <h2 className="footerSection1-intro">Have any Query?</h2>
        <h2 className="footerSection1-intro">Let's talk!</h2>
        <h5>
          Take an appointment or email us and connect with our experts. Get a best solution of any eye related problem in just few steps.
        </h5>
      </div>
      <div className="footerSection1">
        <h2 className="footerSection1-intro">Your eye problem</h2>
        <h2 className="footerSection1-intro">Let me know here.</h2>
        <h4 className="inputTitle">Name</h4>
        <input></input>
        <h4 className="inputTitle">Email</h4>
        <input type="e-mail"></input>
        <h4 className="inputTitle">Number</h4>
        <input ></input>
        <h4 className="inputTitle">Problem</h4>
        <input></input>
        <button>Send ➜</button>
      </div>
    </div>


    <div className="footerSection2">
        <h2 className="brandName">EYECARETAKER</h2>
        <h5>Thanks for Visiting</h5>
        <h5>All rights reserved 2023 @eyecaretaker</h5>

        <div className="socialIcons">
          <a href="https://www.facebook.com/people/Eye-caretaker/100095520750889/?mibextid=LQQJ4d" target="_blank">
            <FaFacebookF className="facebook" />
          </a>
          {/* <a href="https://www.youtube.com/@EyeCaretaker" target="_blank">
            <FaTwitter className="twitter" />
          </a> */}
          <a href="https://www.instagram.com/eyecaretaker/" target="_blank">
            <FaInstagram className="instagram" />
          </a>
          <a href="https://www.youtube.com/@EyeCaretaker" target="_blank">
            <FaYoutube className="youtube" />
          </a>
        </div>
    </div>
    </div>
  );
};

export default Footer;
